.main-content.home {
  background: #e9eaeb;
  /* TODO: replace temporary solid color with background image */
  position: relative;
}

.main-content.home h2 {
  font-size: 32px;
  font-weight: bold;
  color: #0094D5;
  text-align: center;
}

.main-content.home .container {
  max-width: 1200px;
  margin: 0 auto;
  position: inherit;
}

.main-content.home .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 2rem 1rem 100px;
}

.main-content.home .column {
  flex: 25%;
  max-width: 300px;
  padding: 0 1rem;
}


.main-content.home .nav-tile {
  padding: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  min-height: 248px;
  border: 2px solid #0094D5;
  vertical-align: middle;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-content.home .nav-tile img,
.main-content.home .nav-tile svg {
  width: 100%;
  height: auto;
}

.main-content.home .nav-tile-icon.interact {
  width: 120px;
  margin: 0 auto -10px;
}

.main-content.home .nav-tile-icon.play {
  width: 60px;
}

.main-content.home .nav-tile-icon.play.first {
  /* margin: 0.25rem auto 0.75rem; */
}

.main-content.home .nav-tile-icon.play.second {
  margin: 0.75rem auto 1.25rem;
}

.main-content.home .nav-tile-title {
  color: #0094D5;
  font-size: 24px;
  text-align: center;
}

.main-content.home .nav-tile-title p {
  margin: 0;
  line-height: 1.2;
}

@media only screen and (min-width: 768px) {
  .main-content.home {
    min-height: 832px;
  }

  .main-content.home .container {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .main-content.home .row {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .main-content.home {
    height: fit-content;
  }

  .main-content.home .row {
    padding-bottom: 250px;
  }

  .main-content.home .column {
    flex: 100%;
    max-width: 400px;
  }
}

@media only screen and (min-width: 2000px) {
  .main-content.home {
    min-height: 1600px;
  }

  .main-content.home .container {
    max-width: 2000px;
  }

  .main-content.home h2 {
    font-size: 64px;
  }

  .main-content.home .row {
    padding: 4rem 2rem 200px;
  }
  .main-content.home .column {
    max-width: 600px;
    padding: 0 2rem;
  }

  .main-content.home .nav-tile {
    padding: 4rem;
    margin-bottom: 4rem;
    min-height: 492px;
  }

  .main-content.home .nav-tile-icon.interact {
    width: 240px;
    margin: 0 auto -20px;
  }

  .main-content.home .nav-tile-icon.play {
    width: 120px;
  }

  .main-content.home .nav-tile-icon.play.second {
    margin: 1.5rem auto 2.5rem;
  }

  .main-content.home .nav-tile-title {
    font-size: 48px;
  }
}

@media only screen and (min-width: 3000px) {
  .main-content.home {
    min-height: 2160px;
  }

  .main-content.home .container {
    max-width: 3000px;
  }

  .main-content.home h2 {
    font-size: 96px;
  }

  .main-content.home .row {
    padding: 0 3rem 200px;
  }
  .main-content.home .column {
    max-width: 900px;
    padding: 0 3rem;
  }

  .main-content.home .nav-tile {
    padding: 6rem;
    margin-bottom: 5rem;
    min-height: 738px;
  }

  .main-content.home .nav-tile-icon.interact {
    width: 360px;
    margin: 0 auto -30px;
  }

  .main-content.home .nav-tile-icon.play {
    width: 180px;
  }

  .main-content.home .nav-tile-icon.play.second {
    margin: 2.25rem auto 3.75rem;
  }

  .main-content.home .nav-tile-title {
    font-size: 72px;
  }
}

.text-blue {
  font-weight: bold;
}
.thank-you {
  max-width: 1600px;
  margin: 0 auto;
}

.thank-you .hero img {
  width: 100%;
}

.thank-you .content {
  padding: 4rem;
  line-height: 1.2;
  font-family: sans-serif;
}

.thank-you .content .thank-you-tile {
  background-color: #004a97;
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  padding: 4rem;
  color: #fff;
  border-radius: 1.5rem;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(120,120,120,0.7);
  -moz-box-shadow: 0px 5px 15px 0px rgba(120,120,120,0.7);
  box-shadow: 0px 5px 15px 0px rgba(120,120,120,0.7);
}

.thank-you .content .thank-you-tile h2 {
  font-size: 2.75rem;
  font-weight: 300;
  margin: 0;
}

.thank-you .content .thank-you-tile p {
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .thank-you .content {
    padding: 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .thank-you .content .thank-you-tile {
    padding: 2rem;
  }

  .thank-you .content .thank-you-tile h2 {
    font-size: 1.8rem;
  }
  .thank-you .content .thank-you-tile p {
    font-size:1.15rem;
  }
}
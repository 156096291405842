table,
th,
td {
    border: 1px solid black;
}

.map-table {
    padding-left: 500px;
    padding-top: 100px;

}

.download{
   
    font-size:15px;
    margin:6px 24px;
    border-radius:6px;
    
}
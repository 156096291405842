.main-form {
  max-width: 1600px;
  margin: 0 auto;
}

.main-form .hero img {
  width: 100%;
}

.main-form .content {
  padding: 4rem;
  line-height: 1.5;
  font-family: sans-serif;
}

.main-form .content h2 {
  margin-top: 0;
}

.main-form .question {
  margin-bottom: 2rem;
}

.main-form .question .select-field {
  display: inline-flex;
  flex-direction: column;
  min-width: 210px;
  padding-right: 1rem;
}

.main-form .question .select-field label{
  margin-bottom: 0.75rem;
}

.main-form .question .select-field select {
  -webkit-appearance: none;
  padding: 0.5rem 1.75rem 0.5rem 0.8rem;
  border-radius: 0;
  border: 1px solid #959595;
  background: white;
  color: black;
  width: 100%;
}

.main-form .question .select-field .select-wrapper {
  position: relative;
  width: fit-content;
}

.main-form .question .select-field .select-wrapper::after {
  content: "\203A";
  font-size: 1.5rem;
  line-height: 33px;;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1.75rem;
  text-align: center;
  pointer-events: none;
}

.main-form .question label {
  font-size: 1.25rem;
}

.main-form .question>label:first-child {
  display: block;
  margin-bottom: 1rem;
}

.main-form .question label.radio-button {
  display: block;
  font-size: 1rem;
  margin-bottom: 0.75rem;
}

.main-form input[type="radio"] {
  margin-right: 4rem;
}

.main-form .question label.checkbox {
  font-size: 1rem;
  min-width: 146px;
  margin-bottom: 0.75rem;
}

.main-form input[type="checkbox"] {
  margin-right: 1rem;
}

.main-form .question .checkbox-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.5rem;
}

.main-form input[type="submit"] {
  -webkit-appearance: none;
  padding: 0.75rem 1.5rem;
  border: none;
  background: #004a97;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  min-width: 150px;
}

.main-form input[type="submit"]:hover {
  background: #0094D5;
}

@media only screen and (max-width: 767px) {
  .main-form .content {
    padding: 1rem 1rem 6rem;
  }

  .main-form .question .select-field {
    display: flex;
    margin-bottom: 1rem;
  }

  .main-form .question .select-field .select-wrapper {
    width: 100%;
  }

  .main-form .question>label:first-child {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .main-form .question label.radio-button,
  .main-form .question label.checkbox {
    font-size: 0.875rem
  }

  .main-form .question .checkbox-group {
    margin-bottom: 2rem;
  }

  .main-form input[type="submit"] {
    display: block;
    margin: 0 auto;
  }
}

.main-form .validation-error {
  color: red;
  display: block;
  font-size: 0.875rem;
  margin-top: -0.75rem;
  margin-bottom: 1rem;
}

.main-form .no-validation-error {
  display: None;
}

.main-form .tabs {
  display: flex;
  gap: .5rem;
  margin-bottom: 3rem;
}

.main-form .tab {
  -webkit-appearance: none;
  padding: 0.75rem 1.5rem;
  border: none;
  background: #fff;
  font-weight: 700;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  border: 1px solid black;
  flex: 1;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.main-form .tab.selected {
  background: #004a97;
  color: #fff;
}

@media (max-width: 767px) {
  .main-form .tabs {
    flex-wrap: wrap;
  }

  .main-form .tab {
    flex-basis: 49%;
  }
}
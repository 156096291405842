*, *:before, *:after {
    box-sizing: border-box;
}

.main-content {
    height: 100vh;
}

.main-content.bg-enabled {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.main-content.bg-enabled:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,0.7);
}
.header {
    position: absolute;
    cursor: pointer;
    z-index: 10;
}

.header .border {
    background: #0094D5;
    width: 75px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header .border img {
    width: 65px;
    height: auto;
}

@media only screen and (min-width: 2000px) {
    .header .border {
        width: 150px;
        height: 160px;
    }
    .header .border img {
        width: 130px;
    }
}

@media only screen and (min-width: 3000px) {
    .header .border {
        width: 225px;
        height: 240px;
    }
    .header .border img {
        width: 195px;
    }
}
.map-wrapper {
    overflow: hidden;
    position: relative;
    width: 100svw;
    height: 100svh;
    /* font-family: Helvetica, Arial, sans-serif; */
}

.map-counties, .map-dots {
    position: absolute;
    top: 1%;
    left: 0;
    width: 100%;
    height: 80%;
    margin-left: 50%;
    transform: translateX(-50%);

    & svg {
        width: auto;
        height: 100%;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    & svg:not(:first-child) {
        display: none;
    }
}

.map-counties {
    background: url('../map-placeholder.png');
    background-size: auto 77.95svh;
    background-repeat: no-repeat;
    background-position: center 1.6svh;
}

@media (max-width: 1654px) {
    .map-counties {
        background: none;
    }
}

.map-overlay {
    position: relative;
    top: 0;
    left: 0;
    width: calc(100svw - 5svw);
    height: calc(100svh - 5svw);
    margin: 2.5svw;

    & .map-overlay__text {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 75svw;

        & h1 {
            font-size: 3.5svw;
            font-weight: 900;
            margin-bottom: .25svw;
        }

        & p {
            font-size: 2.25svw;
            font-weight: 300;
            margin: 0;
        }
    }

    & .map-overlay__logo {
        position: absolute;
        right: 0;
        bottom: 0;
        background: #3795ca;
        padding: 1.75svw 75px 1.75svw 1.75svw;
        margin-right: -75px;
        margin-bottom: -10px;

        & svg {
            width: 10svw;
            height: auto;
        }
    }

    & .map-overlay__legend {
        display: flex;
        flex-direction: column;
        gap: .5svw;
        position: absolute;
        bottom: 20svh;
        left: calc(50% - 28svw);

        & .map-overlay__legendItem {
            display: flex;
            gap: .5svw;

            & .map-overlay__legendItem__color {
                width: 2svw;
                height: 2vw;
            }

            & .map-overlay__legendItem__label {
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                font-size: 1.25svw;
            }
        }   

        &:not(.show) {
            display: none;
        }
    }
}
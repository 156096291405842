.contact-form {
    max-width: 1600px;
    margin: 0 auto;
  }
  
  .contact-form .hero img {
    width: 100%;
  }
  
  .contact-form .content {
    /* padding: 4rem 4rem 2rem; */
    padding: 4rem;
    line-height: 1.2;
    font-family: sans-serif;
  }
  
  .contact-form .content h3 {
    margin-top: 0;
  }
  
  .contact-form .question {
    margin-bottom: 2rem;
  }
  
  .contact-form .question label {
    font-size: 1rem;
  }
  
  .contact-form .question > label:first-child {
    display: block;
    margin-bottom: 1rem;
  }
  
  .contact-form form .text-input-group {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -0.5rem;
    width: calc(100% + 0.5rem);
  }
  
  .contact-form form .text-input-group .form-field {
    margin: 0 0 1rem 0.5rem;
  }
  
  .contact-form form .text-input-group input[type="text"],
  .contact-form form .text-input-group input[type="number"],
  .contact-form form .text-input-group select {
    -webkit-appearance: none;
    padding: 0.5rem 0.8rem;
    border-radius: 0;
    border: 1px solid #959595;
    width: 100%;
  }
  
  .contact-form form .text-input-group input::placeholder {
    color: black;
    opacity: 1;
  }
  
  .contact-form form .text-input-group input::-moz-placeholder {
    color: black;
    opacity: 1;
  }
  
  .contact-form form .text-input-group input::-ms-input-placeholder {
    color: black;
    opacity: 1;
  }
  
  .contact-form form .text-input-group input::-webkit-input-placeholder {
    color: black;
    opacity: 1;
  }
  
  
  .contact-form form select {
    -webkit-appearance: none;
    padding: 0.5rem 1.75rem 0.5rem 0.8rem !important;
    border-radius: 0;
    border: 1px solid #959595;
    background: white;
    color: black;
    min-width: 175px;
  }
  
  .contact-form form .select-wrapper {
    position: relative;
    width: fit-content;
  }
  
  .contact-form form .text-input-group .select-wrapper {
    width: 100%;
  }
  
  .contact-form form .select-wrapper::after {
    content: "\203A";
    font-size: 1.5rem;
    line-height: 33px;;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1.75rem;
    text-align: center;
    pointer-events: none;
  }
  
  .contact-form form .text-input-group .form-field.first-name,
  .contact-form form .text-input-group .form-field.last-name,
  .contact-form form .text-input-group .form-field.phone-number,
  .contact-form form .text-input-group .form-field.email {
    width: calc(50%  - 0.75rem);
  }
  
  .contact-form form .text-input-group .form-field.farm-name,
  .contact-form form .text-input-group .form-field.address {
    width: 100%;
  }
  
  .contact-form form .text-input-group .form-field.city,
  .contact-form form .text-input-group .form-field.zipcode {
    width: calc(35% - 1rem);
  }
  
  .contact-form form .text-input-group .form-field.state {
    width: calc(30% - 1rem);
  }
  
  .contact-form form .text-input-group .form-field .validation-error {
    margin-top: 0.5rem;
  }
  
  .contact-form form .validation-error{
    color:red;
    display: block;
    font-size: 0.875rem;
  }
  .contact-form form .no-validation-error{
    display: None;
  }
  
  .contact-form form label + .validation-error {
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
  }
  .contact-form form p.disclaimer + .validation-error {
    margin-top: -2px;
    margin-bottom: 0.5rem;
  }
  
  .contact-form form textarea {
    display: block;
    width: 100%;
    margin-top: 1rem;
    padding: 0.5rem 0.8rem;
    -webkit-appearance: none;
    border: 1px solid #959595;
    color: black;
  } 
  
  .contact-form .question .checkbox-group {
    display: flex;
    flex-wrap: wrap;
  }
  
  .contact-form .question .checkbox-group label.checkbox {
    font-size: 1rem;
    min-width: 300px;
    margin-bottom: 0.75rem;
  }
  
  .contact-form .disclaimer {
    font-size: 12px;
  }
  
  .contact-form .disclaimer a {
    color: inherit;
    font-style: inherit;
    font-weight: inherit;
    text-decoration: underline;
  }
  
  .contact-form form input[type="submit"] {
    -webkit-appearance: none;
    padding: 0.75rem 1.5rem;
    border: none;
    background: #004a97;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    min-width: 150px;
  }
  
  .contact-form form input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .contact-form form input[type="submit"]:hover {
    background: #0094D5;
  }
  
  /* .contact-form footer {
    font-size: 10px;
    padding: 0 4rem 4rem;
  } */
  
  .contact-form a {
    color: #0094D5;
    font-weight: 700;
    font-style: italic;
    text-decoration: none;
  }
  
  .contact-form a:hover {
    color: #004a97;
  }
  
  @media only screen and (max-width: 767px) {
    .contact-form .content {
      padding: 1rem 1rem 6rem;
    }
  
    .contact-form form .text-input-group .form-field {
      width: 100% !important;
    }
  
    .contact-form form .select-wrapper,
    .contact-form form select {
      width: 100%;
    }
  
    /* .contact-form footer {
      padding: 0 1rem 1rem;
    } */
  }
.main-content.tools {
    position: relative;
    min-height: 892.5px;
}

.main-content.tools .iframe {
    margin: 0 auto;
    padding: 0 2rem;
    max-width: 1200px;
    position: inherit;
    height: 750px;
}

.main-content.tools .iframe iframe {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1285px) {
    .main-content.tools .iframe {
        padding-top: calc(80px + 2rem);
    }
}

@media (min-width: 767px) and (max-width: 1285px) {
    .main-content.tools {
        min-height: 1337.5px;
    }
    .main-content.tools .iframe {
        height: 1195px;
    }
}

@media only screen and (max-width: 767px) {
    .main-content.tools {
        min-height: 1510.5px;
    }
    .main-content.tools .iframe {
        height: 1250px;
    }
}

@media only screen and (max-width: 575px) {
    .main-content.tools {
        min-height: 865px;
    }
    .main-content.tools .iframe {
        height: calc(100vh - 260.5px);
        min-height: 604px;
    }
}

@media only screen and (min-width: 2000px) {
    .main-content.tools {
        min-height: 1235px;
    }
    .main-content.tools .iframe {
        padding: 0 4rem;
        max-width: 1820px;
        height: 960px;
    }
}

@media only screen and (min-width: 3000px) {
    .main-content.tools {
        min-height: 1712px;
    }
    .main-content.tools .iframe {
        padding: 0 6rem;
        max-width: 2675px;
        height: 1380px;
    }
}

@media only screen and (min-width: 3840px) {
    .main-content.tools {
        min-height: 2010px;
    }
    .main-content.tools .iframe {
        padding: 0 6rem;
        max-width: 3200px;
        height: 1690px;
    }
}
.main-content.play-video {
    background: #e9eaeb; /* TODO: replace temporary solid color with background image */
    position: relative;
    min-height: 775px;
}

.main-content.play-video .video {
    margin: 0 auto;
    padding: 0 2rem;
    max-width: 1200px;
}

@media only screen and (max-width: 1285px) {
    .main-content.play-video .video {
        padding-top: calc(80px + 2rem);
    }
}

@media (min-width: 767px) and (max-width: 1285px) {
    .main-content.play-video {
        min-height: calc(100vw * 0.5625 + 180px + 4rem);
    }
}

@media only screen and (max-width: 767px) {
    .main-content.play-video {
        min-height: calc((100vw - 4rem) * 0.5625 + 298px + 4rem);
    }
}

@media only screen and (min-width: 2000px) {
    .main-content.play-video {
        min-height: 1235px;
    }
    .main-content.play-video .video {
        padding: 0 4rem;
        max-width: 1820px;
    }
}

@media only screen and (min-width: 3000px) {
    .main-content.play-video {
        min-height: 1712px;
    }
    .main-content.play-video .video {
        padding: 0 6rem;
        max-width: 2675px;
    }
}

@media only screen and (min-width: 3840px) {
    .main-content.play-video {
        min-height: 2010px;
    }
    .main-content.play-video .video {
        padding: 0 6rem;
        max-width: 3200px;
    }
}
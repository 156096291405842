.footer {
    position: absolute;
    bottom: 2rem;
    width: 100%;
}

.footer-qrcode {
    display: flex;
    align-items: center;
    float: left;
    margin-right: 1rem;
    margin-left: 2rem;
}

.footer-qrcode img {
    width: 75px;
    height: auto;
}

.footer-qrcode .qr-message {
    padding-left: 1rem;
}

.footer-qrcode .qr-message p {
    font-size: 18px;
    margin: 0;
}

.footer-logo {
    float: right;
}

.footer-logo img {
    width: auto;
    height: 75px;
}

.text-blue {
    color: #0094D5 !important;
}

@media only screen and (max-width: 767px) {
    .footer {
        max-width: 266px;
        right: 0;
    }
    .footer-qrcode {
        margin-bottom: 2rem;
        margin-left: 0;
    }
}

@media only screen and (min-width: 1200px) {
    .footer-qrcode {
        margin-left: calc(50vw - 600px + 2rem);
    }
}

@media only screen and (min-width: 2000px) {
    .footer {
        bottom: 4rem;
    }

    .footer-qrcode {
        margin-right: 2rem;
        margin-left: calc(50vw - 910px + 4rem);
    }

    .footer-qrcode img {
        width: 150px;
    }

    .footer-qrcode .qr-message {
        padding-left: 2rem;
    }
    
    .footer-qrcode .qr-message p {
        font-size: 36px;
    }

    .footer-logo img {
        height: 150px;
    }
}

@media only screen and (min-width: 3000px) {
    .footer {
        bottom: 4rem;
    }

    .footer-qrcode {
        margin-right: 3rem;
        margin-left: calc(50vw - 1337.5px + 6rem);
    }

    .footer-qrcode img {
        width: 180px;
    }

    .footer-qrcode .qr-message {
        padding-left: 3rem;
    }
    
    .footer-qrcode .qr-message p {
        font-size: 54px;
    }

    .footer-logo img {
        height: 180px;
    }
}

@media only screen and (min-width: 3840px) {
    .footer-qrcode {
        margin-left: calc(50vw - 1600px + 6rem);
    }
}